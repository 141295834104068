import { Component } from '@angular/core';
import {animate, animateChild, group, query, state, style, transition, trigger} from "@angular/animations";

enum MessageToastState {
    Hidden = 'hidden',
    Visible = 'visible',
}

export interface MessageToastOptions {
    title?: string;
    message?: string;
    type?: 'success' | 'error' | 'info';
    timeout?: number;
}

const DefaultTimeout = 5000;
const AnimationDuration = '0.3s';

@Component({
    selector: 'app-message-toast',
    templateUrl: './message-toast.component.html',
    styleUrls: ['./message-toast.component.scss'],
    animations: [
        trigger('reveal', [
            state(MessageToastState.Hidden, style({ transform: 'translateX(100%) translateY(0%)' })),
            state(MessageToastState.Visible, style({ transform: 'translateX(0%) translateY(0%)' })),
            transition('* => *', [
                group([
                    animate(`${AnimationDuration} linear`),
                    query('@handle-reveal', animateChild()),
                ])
            ]),
        ]),
        trigger('handle-reveal', [
            state(MessageToastState.Hidden, style({ display: 'flex', opacity: 1, transform: 'translateX(0%)' })),
            state(MessageToastState.Visible, style({ display: 'none', opacity: 0, transform: 'translateX(100%)' })),
            transition('* => *', animate(`${AnimationDuration} linear`)),
        ])
    ],
    standalone: true
})
export class MessageToastComponent {

    title?: string = 'Title';
    text: string = 'no message';
    state = MessageToastState.Hidden;
    private scheduledTimeout: number | undefined;

    constructor() { }

    private cancelScheduledTimeout() {
        if (this.scheduledTimeout) {
            clearTimeout(this.scheduledTimeout);
            this.scheduledTimeout = undefined;
        }
    }

    show(options: MessageToastOptions) {
        this.cancelScheduledTimeout();
        if (options.message) {
            this.title = options.title;
            this.text = options.message;
        }
        this.state = MessageToastState.Visible;
        // this.scheduledTimeout = setTimeout(() => this.hide(), options.timeout ?? DefaultTimeout);
    }

    hide() {
        this.cancelScheduledTimeout();
        this.state = MessageToastState.Hidden;
    }

    onToastClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.hide();
        // this.cancelScheduledTimeout();
        // this.show({timeout: 10_000});
    }

    onHandleClick(event: MouseEvent) {
        event.preventDefault();
        event.stopPropagation();
        this.show({});
    }
}
