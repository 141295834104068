import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {allComponents, provideFluentDesignSystem,} from '@fluentui/web-components';

import {AuthService} from "./app/service/auth.service";
import { importProvidersFrom } from '@angular/core';
import { AppComponent } from './app/app.component';
import { AppRoutingModule } from './app/app-routing.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { PocApi, Configuration, Middleware, ResponseContext, RequestContext, FetchParams } from './api-client/src';

const apiConfig =  new Configuration({
    // apiKey: (name: string) => "api-key-for-testing",
    accessToken: () => AuthService.accessToken(),
    basePath: basePath()
});

function basePath(): string {
    switch (document.location.hostname) {
        case 'localhost': return 'http://localhost:8090';
        case 'heimat9': return 'http://heimat9:8090';
        default: return `https://${document.location.hostname}`;
    }
}
class LogInterceptor implements Middleware {
    post(context: ResponseContext): Promise<Response | void> {
        console.debug(`LogInterceptor ${context.init.method}`, context);
        return Promise.resolve();
    }

    pre(context: RequestContext): Promise<FetchParams | void> {
        console.debug(`LogInterceptor ${context.init.method}`, context);
        return Promise.resolve();
    }
}

const apiClient = new PocApi(apiConfig)
    .withMiddleware(new LogInterceptor());


// initialize the Fluent Design System to be able to display components
export const FluentDesignSystem = provideFluentDesignSystem().register(
    allComponents
);

async function main() {
    const isAuthInitialized = await AuthService.initKeycloak();

    if (isAuthInitialized) {
        bootstrapApplication(AppComponent, {
            providers: [
                importProvidersFrom(BrowserModule, AppRoutingModule),
                { provide: PocApi, useValue: apiClient },
                provideAnimations()
            ]
        })
            .catch(err => console.error(err));
    }
}

main().then(r => console.log('main finished'));
