/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  DifficultyResponse,
  DumpResult,
  SolutionResponse,
  WordlGameEvaluation,
  WordlGuessEvaluation,
  WordleAiCommentResponse,
  WordleEvalCommentRequest,
  WordleEvalMultiRequest,
  WordleRandomResult,
} from '../models/index';
import {
    DifficultyResponseFromJSON,
    DifficultyResponseToJSON,
    DumpResultFromJSON,
    DumpResultToJSON,
    SolutionResponseFromJSON,
    SolutionResponseToJSON,
    WordlGameEvaluationFromJSON,
    WordlGameEvaluationToJSON,
    WordlGuessEvaluationFromJSON,
    WordlGuessEvaluationToJSON,
    WordleAiCommentResponseFromJSON,
    WordleAiCommentResponseToJSON,
    WordleEvalCommentRequestFromJSON,
    WordleEvalCommentRequestToJSON,
    WordleEvalMultiRequestFromJSON,
    WordleEvalMultiRequestToJSON,
    WordleRandomResultFromJSON,
    WordleRandomResultToJSON,
} from '../models/index';

export interface ChatChptCommentGuessesRequest {
    wordleEvalCommentRequest: WordleEvalCommentRequest;
    commentType?: ChatChptCommentGuessesCommentTypeEnum;
}

export interface CheckWordValidityRequest {
    word: string;
}

export interface EvalGuessesRequest {
    solution: string;
    guess: string;
}

export interface EvalGuesses1Request {
    wordleEvalMultiRequest: WordleEvalMultiRequest;
}

export interface RandomWordRequest {
    length?: number;
}

export interface RankDifficultyRequest {
    solution: string;
    cycles?: number;
}

export interface RankDifficulty0Request {
    solution: string;
    cycles?: number;
}

export interface SolveRequest {
    solution: string;
}

/**
 * 
 */
export class PocApi extends runtime.BaseAPI {

    /**
     */
    async chatChptCommentGuessesRaw(requestParameters: ChatChptCommentGuessesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WordleAiCommentResponse>> {
        if (requestParameters.wordleEvalCommentRequest === null || requestParameters.wordleEvalCommentRequest === undefined) {
            throw new runtime.RequiredError('wordleEvalCommentRequest','Required parameter requestParameters.wordleEvalCommentRequest was null or undefined when calling chatChptCommentGuesses.');
        }

        const queryParameters: any = {};

        if (requestParameters.commentType !== undefined) {
            queryParameters['commentType'] = requestParameters.commentType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("oauth2", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/wordle/v1/guess/aicommment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WordleEvalCommentRequestToJSON(requestParameters.wordleEvalCommentRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WordleAiCommentResponseFromJSON(jsonValue));
    }

    /**
     */
    async chatChptCommentGuesses(requestParameters: ChatChptCommentGuessesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WordleAiCommentResponse> {
        const response = await this.chatChptCommentGuessesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async checkWordValidityRaw(requestParameters: CheckWordValidityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters.word === null || requestParameters.word === undefined) {
            throw new runtime.RequiredError('word','Required parameter requestParameters.word was null or undefined when calling checkWordValidity.');
        }

        const queryParameters: any = {};

        if (requestParameters.word !== undefined) {
            queryParameters['word'] = requestParameters.word;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("oauth2", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/wordle/v1/guess/isvalid`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async checkWordValidity(requestParameters: CheckWordValidityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.checkWordValidityRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async dumpDictionaryRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DumpResult>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("oauth2", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/poc/v1/dict/dump`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DumpResultFromJSON(jsonValue));
    }

    /**
     */
    async dumpDictionary(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DumpResult> {
        const response = await this.dumpDictionaryRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async evalGuessesRaw(requestParameters: EvalGuessesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WordlGuessEvaluation>> {
        if (requestParameters.solution === null || requestParameters.solution === undefined) {
            throw new runtime.RequiredError('solution','Required parameter requestParameters.solution was null or undefined when calling evalGuesses.');
        }

        if (requestParameters.guess === null || requestParameters.guess === undefined) {
            throw new runtime.RequiredError('guess','Required parameter requestParameters.guess was null or undefined when calling evalGuesses.');
        }

        const queryParameters: any = {};

        if (requestParameters.solution !== undefined) {
            queryParameters['solution'] = requestParameters.solution;
        }

        if (requestParameters.guess !== undefined) {
            queryParameters['guess'] = requestParameters.guess;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("oauth2", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/wordle/v1/guess/evalsingle`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WordlGuessEvaluationFromJSON(jsonValue));
    }

    /**
     */
    async evalGuesses(requestParameters: EvalGuessesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WordlGuessEvaluation> {
        const response = await this.evalGuessesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async evalGuesses1Raw(requestParameters: EvalGuesses1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WordlGameEvaluation>> {
        if (requestParameters.wordleEvalMultiRequest === null || requestParameters.wordleEvalMultiRequest === undefined) {
            throw new runtime.RequiredError('wordleEvalMultiRequest','Required parameter requestParameters.wordleEvalMultiRequest was null or undefined when calling evalGuesses1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("oauth2", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/wordle/v1/guess/evalmulti`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: WordleEvalMultiRequestToJSON(requestParameters.wordleEvalMultiRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WordlGameEvaluationFromJSON(jsonValue));
    }

    /**
     */
    async evalGuesses1(requestParameters: EvalGuesses1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WordlGameEvaluation> {
        const response = await this.evalGuesses1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async randomWordRaw(requestParameters: RandomWordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<WordleRandomResult>> {
        const queryParameters: any = {};

        if (requestParameters.length !== undefined) {
            queryParameters['length'] = requestParameters.length;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("oauth2", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/poc/v1/wordle/random`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WordleRandomResultFromJSON(jsonValue));
    }

    /**
     */
    async randomWord(requestParameters: RandomWordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<WordleRandomResult> {
        const response = await this.randomWordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rankDifficultyRaw(requestParameters: RankDifficultyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DifficultyResponse>> {
        if (requestParameters.solution === null || requestParameters.solution === undefined) {
            throw new runtime.RequiredError('solution','Required parameter requestParameters.solution was null or undefined when calling rankDifficulty.');
        }

        const queryParameters: any = {};

        if (requestParameters.solution !== undefined) {
            queryParameters['solution'] = requestParameters.solution;
        }

        if (requestParameters.cycles !== undefined) {
            queryParameters['cycles'] = requestParameters.cycles;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("oauth2", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/poc/v1/wordle/difficulty`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DifficultyResponseFromJSON(jsonValue));
    }

    /**
     */
    async rankDifficulty(requestParameters: RankDifficultyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DifficultyResponse> {
        const response = await this.rankDifficultyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async rankDifficulty0Raw(requestParameters: RankDifficulty0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DifficultyResponse>> {
        if (requestParameters.solution === null || requestParameters.solution === undefined) {
            throw new runtime.RequiredError('solution','Required parameter requestParameters.solution was null or undefined when calling rankDifficulty0.');
        }

        const queryParameters: any = {};

        if (requestParameters.solution !== undefined) {
            queryParameters['solution'] = requestParameters.solution;
        }

        if (requestParameters.cycles !== undefined) {
            queryParameters['cycles'] = requestParameters.cycles;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("oauth2", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/poc/v1/wordle/difficulty0`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DifficultyResponseFromJSON(jsonValue));
    }

    /**
     */
    async rankDifficulty0(requestParameters: RankDifficulty0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DifficultyResponse> {
        const response = await this.rankDifficulty0Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async solveRaw(requestParameters: SolveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SolutionResponse>> {
        if (requestParameters.solution === null || requestParameters.solution === undefined) {
            throw new runtime.RequiredError('solution','Required parameter requestParameters.solution was null or undefined when calling solve.');
        }

        const queryParameters: any = {};

        if (requestParameters.solution !== undefined) {
            queryParameters['solution'] = requestParameters.solution;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("oauth2", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/poc/v1/wordle/solve`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SolutionResponseFromJSON(jsonValue));
    }

    /**
     */
    async solve(requestParameters: SolveRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SolutionResponse> {
        const response = await this.solveRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async testRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<string>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("oauth2", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/poc/v1/test`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<string>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     */
    async test(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<string> {
        const response = await this.testRaw(initOverrides);
        return await response.value();
    }

}

/**
 * @export
 */
export const ChatChptCommentGuessesCommentTypeEnum = {
    Ironic: 'ironic',
    Cynic: 'cynic',
    Depressed: 'depressed',
    Comforting: 'comforting',
    Strict: 'strict',
    Tip: 'tip',
    Joke: 'joke',
    Rhyme: 'rhyme'
} as const;
export type ChatChptCommentGuessesCommentTypeEnum = typeof ChatChptCommentGuessesCommentTypeEnum[keyof typeof ChatChptCommentGuessesCommentTypeEnum];
