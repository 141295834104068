import {Component, ElementRef, HostListener, inject, Input} from '@angular/core';
import {WordleAppStoreService, WordleGameStatus} from "../wordle-app-store.service";
import {animate, state, style, transition, trigger} from "@angular/animations";
import {ChatChptCommentGuessesCommentTypeEnum, PocApi} from "../../api-client/src";
import {MessageToastComponent} from "../message-toast/message-toast.component";
import {AppComponent} from "../app.component";
import { NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-wordle-grid',
    templateUrl: './wordle-grid.component.html',
    styleUrls: ['./wordle-grid.component.scss'],
    animations: [
        // trigger for 'reveal', which should increase the opacity of the cell
        // from 0 to 1 over 0.5 seconds
        trigger('reveal', [
            state('default', style({ 'background-size': '0% 100%' })),
            state('revealed', style({ 'background-size': '100% 100%' })),
            transition('default => revealed', animate('.3s linear')),
        ]),
    ],
    standalone: true,
    imports: [NgFor, NgClass]
})
export class WordleGridComponent {

    rowSizeRange: number[] = [];
    colSizeRange: number[] = [];
    hostElement: HTMLElement = inject(ElementRef).nativeElement;

    gameStore = inject(WordleAppStoreService);
    pocApi = inject(PocApi);

    focusColumn = 0;
    currentGuess: string[] = [];
    revealedColumn = Number.MAX_SAFE_INTEGER;

    constructor() { }

    ngOnInit(): void {
        const size = this.gameStore.wordleSize;
        this.rowSizeRange = Array(size + 1).fill(0).map((x,i)=>i);
        this.colSizeRange = Array(size).fill(0).map((x, i)=>i);
        this.hostElement.style.setProperty('--grid-size', size.toString());
        this.currentGuess = Array(size).fill('');
    }

    private focusNextColumn() {
        this.focusColumn = Math.min(this.focusColumn + 1, this.gameStore.wordleSize - 1);
    }

    private focusPreviousColumn() {
        this.focusColumn = Math.max(this.focusColumn - 1, 0);
    }

    // subscribe to keypresses
    @HostListener('window:keydown', ['$event'])
    async keyEvent(event: KeyboardEvent) {
        // @ts-ignore:
        document.activeElement?.blur();
        if (event.altKey || event.ctrlKey || event.metaKey) return;

        let isProcessedInApp = true;

        if (event.key === 'Backspace') {
            if (this.currentGuess[this.focusColumn] == '') {
                this.focusPreviousColumn();
            }
            this.currentGuess[this.focusColumn] = '';
        } else if (event.key === 'ArrowLeft') {
            this.focusPreviousColumn();
        } else if (event.key === 'ArrowRight') {
            this.focusNextColumn();
        } else if (event.key === 'Enter') {
            await this.submitGuess();
        } else if (event.key.length === 1) {
            this.currentGuess[this.focusColumn] = event.key.toUpperCase();
            this.focusNextColumn();
        } else {
            isProcessedInApp = false;
        }

        if (isProcessedInApp) {
            event.preventDefault();
            event.stopPropagation();
        }
    }

    cell(guessNo: number, charNo: number) {
        if (guessNo < this.gameStore.numGuesses && charNo < this.gameStore.wordleSize) {
            return this.gameStore.guessList[guessNo][charNo];
        } else if (guessNo === this.gameStore.numGuesses && charNo < this.gameStore.wordleSize) {
            return this.currentGuess[charNo];
        } else {
            return '';
        }
    }

    cellState(guessNo: number, charNo: number): string {
        if (guessNo < this.gameStore.numGuesses && charNo < this.gameStore.wordleSize) {
            return this.gameStore.guessListEval[guessNo][charNo].toString();
        } else {
            return '';
        }
    }

    cellRevealed(guessNo: number, charNo: number): string {
        if (guessNo < this.gameStore.numGuesses - 1) return 'revealed';
        else if (guessNo === this.gameStore.numGuesses - 1) {
            return (charNo < this.revealedColumn) ? 'revealed' : 'default';
        }
        else return 'default';
    }

    hasFocus(guessNo: number, charNo: number): boolean {
        return charNo === this.focusColumn && guessNo === this.gameStore.numGuesses;
    }

    private async submitGuess() {
        const guess = this.currentGuess.join('');
        const isValid = await this.gameStore.isValidGuess(guess);
        if (isValid) {
            this.gameStore.guess(guess);
            this.currentGuess = Array(this.gameStore.wordleSize).fill('');
            this.focusColumn = 0;
            this.startRevealAnimation();
        } else {
            this.shakeCurrentRow();
        }
    }

    private shakeCurrentRow() {
        const i = this.gameStore.guessList.length + 1;
        const rowCells = this.hostElement.querySelectorAll(`[data-grid-row="${i}"]`);
        rowCells.forEach(it => it.classList.add('invalid'));
        setTimeout(() => rowCells.forEach(it => it.classList.remove('invalid')), 500);
    }

    private animateRevealedColumn() {
        this.revealedColumn++;
        console.log('revealedColumn', this.revealedColumn);
        if (this.revealedColumn < this.gameStore.wordleSize) {
            setTimeout(() => this.animateRevealedColumn(), 300);
        } else {
            setTimeout(() => {
                this.gameStore.updateCharStatus();
                this.gameStore.updateGameStatus();
            }, 500);
            setTimeout(async () => {
                const chatGpt = await this.pocApi.chatChptCommentGuesses({
                    wordleEvalCommentRequest: {
                        guesses: this.gameStore.guessList,
                        solution: this.gameStore.solution,
                        protocol: this.gameStore.chatProtocol
                    },
                    commentType: (this.gameStore.gameStatus() === WordleGameStatus.Won) ? "rhyme" : undefined
                });

                this.gameStore.chatProtocol = chatGpt.protocol ?? [];

                AppComponent.messageToast.show({
                    title: `ChatGPT sagt (${chatGpt.type})`,
                    message: chatGpt.message
                });
            }, 1000)
        }
    }

    private startRevealAnimation() {
        this.revealedColumn = 0;
        this.animateRevealedColumn();
    }

    cellClicked(guessNo: number, charNo: number) {
        if (guessNo === this.gameStore.numGuesses) {
            this.focusColumn = charNo;
        }
    }
}
