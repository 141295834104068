/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CharGuess
 */
export interface CharGuess {
    /**
     * 
     * @type {string}
     * @memberof CharGuess
     */
    _char: string;
    /**
     * 
     * @type {string}
     * @memberof CharGuess
     */
    result: CharGuessResultEnum;
}


/**
 * @export
 */
export const CharGuessResultEnum = {
    Undefined: 'Undefined',
    NoMoreOccurrences: 'NoMoreOccurrences',
    WrongPosition: 'WrongPosition',
    Correct: 'Correct'
} as const;
export type CharGuessResultEnum = typeof CharGuessResultEnum[keyof typeof CharGuessResultEnum];


/**
 * Check if a given object implements the CharGuess interface.
 */
export function instanceOfCharGuess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "_char" in value;
    isInstance = isInstance && "result" in value;

    return isInstance;
}

export function CharGuessFromJSON(json: any): CharGuess {
    return CharGuessFromJSONTyped(json, false);
}

export function CharGuessFromJSONTyped(json: any, ignoreDiscriminator: boolean): CharGuess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        '_char': json['char'],
        'result': json['result'],
    };
}

export function CharGuessToJSON(value?: CharGuess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'char': value._char,
        'result': value.result,
    };
}

