/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface WordlGuessEvaluation
 */
export interface WordlGuessEvaluation {
    /**
     * 
     * @type {string}
     * @memberof WordlGuessEvaluation
     */
    word?: string;
    /**
     * 
     * @type {string}
     * @memberof WordlGuessEvaluation
     */
    guess: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WordlGuessEvaluation
     */
    result: Array<WordlGuessEvaluationResultEnum>;
    /**
     * 
     * @type {string}
     * @memberof WordlGuessEvaluation
     */
    solution: string;
    /**
     * 
     * @type {boolean}
     * @memberof WordlGuessEvaluation
     */
    isCorrect: boolean;
    /**
     * 
     * @type {number}
     * @memberof WordlGuessEvaluation
     */
    numChars: number;
    /**
     * 
     * @type {number}
     * @memberof WordlGuessEvaluation
     */
    numCharsCorrect: number;
    /**
     * 
     * @type {number}
     * @memberof WordlGuessEvaluation
     */
    numCharsExists: number;
    /**
     * 
     * @type {number}
     * @memberof WordlGuessEvaluation
     */
    numCharsIncorrect: number;
}


/**
 * @export
 */
export const WordlGuessEvaluationResultEnum = {
    Unset: 'Unset',
    Exists: 'Exists',
    Correct: 'Correct',
    Incorrect: 'Incorrect'
} as const;
export type WordlGuessEvaluationResultEnum = typeof WordlGuessEvaluationResultEnum[keyof typeof WordlGuessEvaluationResultEnum];


/**
 * Check if a given object implements the WordlGuessEvaluation interface.
 */
export function instanceOfWordlGuessEvaluation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "guess" in value;
    isInstance = isInstance && "result" in value;
    isInstance = isInstance && "solution" in value;
    isInstance = isInstance && "isCorrect" in value;
    isInstance = isInstance && "numChars" in value;
    isInstance = isInstance && "numCharsCorrect" in value;
    isInstance = isInstance && "numCharsExists" in value;
    isInstance = isInstance && "numCharsIncorrect" in value;

    return isInstance;
}

export function WordlGuessEvaluationFromJSON(json: any): WordlGuessEvaluation {
    return WordlGuessEvaluationFromJSONTyped(json, false);
}

export function WordlGuessEvaluationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WordlGuessEvaluation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'word': !exists(json, 'word') ? undefined : json['word'],
        'guess': json['guess'],
        'result': json['result'],
        'solution': json['solution'],
        'isCorrect': json['isCorrect'],
        'numChars': json['numChars'],
        'numCharsCorrect': json['numCharsCorrect'],
        'numCharsExists': json['numCharsExists'],
        'numCharsIncorrect': json['numCharsIncorrect'],
    };
}

export function WordlGuessEvaluationToJSON(value?: WordlGuessEvaluation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'word': value.word,
        'guess': value.guess,
        'result': value.result,
        'solution': value.solution,
        'isCorrect': value.isCorrect,
        'numChars': value.numChars,
        'numCharsCorrect': value.numCharsCorrect,
        'numCharsExists': value.numCharsExists,
        'numCharsIncorrect': value.numCharsIncorrect,
    };
}

