/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { CharGuess } from './CharGuess';
import {
    CharGuessFromJSON,
    CharGuessFromJSONTyped,
    CharGuessToJSON,
} from './CharGuess';
import type { GuessMetrics } from './GuessMetrics';
import {
    GuessMetricsFromJSON,
    GuessMetricsFromJSONTyped,
    GuessMetricsToJSON,
} from './GuessMetrics';

/**
 * 
 * @export
 * @interface Guess
 */
export interface Guess {
    /**
     * 
     * @type {string}
     * @memberof Guess
     */
    word: string;
    /**
     * 
     * @type {Array<CharGuess>}
     * @memberof Guess
     */
    boxes: Array<CharGuess>;
    /**
     * 
     * @type {GuessMetrics}
     * @memberof Guess
     */
    metrics: GuessMetrics;
    /**
     * 
     * @type {boolean}
     * @memberof Guess
     */
    isCorrect: boolean;
}

/**
 * Check if a given object implements the Guess interface.
 */
export function instanceOfGuess(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "word" in value;
    isInstance = isInstance && "boxes" in value;
    isInstance = isInstance && "metrics" in value;
    isInstance = isInstance && "isCorrect" in value;

    return isInstance;
}

export function GuessFromJSON(json: any): Guess {
    return GuessFromJSONTyped(json, false);
}

export function GuessFromJSONTyped(json: any, ignoreDiscriminator: boolean): Guess {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'word': json['word'],
        'boxes': ((json['boxes'] as Array<any>).map(CharGuessFromJSON)),
        'metrics': GuessMetricsFromJSON(json['metrics']),
        'isCorrect': json['isCorrect'],
    };
}

export function GuessToJSON(value?: Guess | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'word': value.word,
        'boxes': ((value.boxes as Array<any>).map(CharGuessToJSON)),
        'metrics': GuessMetricsToJSON(value.metrics),
        'isCorrect': value.isCorrect,
    };
}

