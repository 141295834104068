/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChatCompletionRequestMessage } from './ChatCompletionRequestMessage';
import {
    ChatCompletionRequestMessageFromJSON,
    ChatCompletionRequestMessageFromJSONTyped,
    ChatCompletionRequestMessageToJSON,
} from './ChatCompletionRequestMessage';

/**
 * 
 * @export
 * @interface WordleEvalCommentRequest
 */
export interface WordleEvalCommentRequest {
    /**
     * 
     * @type {string}
     * @memberof WordleEvalCommentRequest
     */
    solution: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WordleEvalCommentRequest
     */
    guesses: Array<string>;
    /**
     * 
     * @type {Array<ChatCompletionRequestMessage>}
     * @memberof WordleEvalCommentRequest
     */
    protocol?: Array<ChatCompletionRequestMessage>;
}

/**
 * Check if a given object implements the WordleEvalCommentRequest interface.
 */
export function instanceOfWordleEvalCommentRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "solution" in value;
    isInstance = isInstance && "guesses" in value;

    return isInstance;
}

export function WordleEvalCommentRequestFromJSON(json: any): WordleEvalCommentRequest {
    return WordleEvalCommentRequestFromJSONTyped(json, false);
}

export function WordleEvalCommentRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): WordleEvalCommentRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'solution': json['solution'],
        'guesses': json['guesses'],
        'protocol': !exists(json, 'protocol') ? undefined : ((json['protocol'] as Array<any>).map(ChatCompletionRequestMessageFromJSON)),
    };
}

export function WordleEvalCommentRequestToJSON(value?: WordleEvalCommentRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'solution': value.solution,
        'guesses': value.guesses,
        'protocol': value.protocol === undefined ? undefined : ((value.protocol as Array<any>).map(ChatCompletionRequestMessageToJSON)),
    };
}

