import {inject, Injectable, signal} from '@angular/core';
import {ChatCompletionRequestMessage, PocApi} from "../api-client/src";

export interface CharCounts {
    [key: string]: number;
}
export enum CharState {
    Unset = 'unset',
    Exists = 'exists',
    Correct = 'correct',
    Incorrect = 'incorrect'
}

export enum WordleGameStatus {
    NotStarted,
    InProgress,
    Won,
    Lost

}

@Injectable({
    providedIn: 'root'
})
export class WordleAppStoreService {

    pocApi = inject(PocApi);

    wordleSize: number = 5;
    solution: string = 'PANNE';
    guessList: string[] = [];
    guessListEval: CharState[][] = [];

    chatProtocol: Array<ChatCompletionRequestMessage> = [];

    charStatus: { [key: string ]: CharState } = {};
    gameStatus = signal(WordleGameStatus.NotStarted);

    dictionary = new Set<string>();

    get numGuesses(): number {
        return this.guessList.length;
    }

    constructor() {
        this.loadDictionary();
        this.loadFromLocalStorage();
    }

    private loadDictionary() {
        fetch('assets/dict5.txt')
            .then(response => response.text())
            .then(text => {
                this.dictionary = new Set(text.split(/\r?\n/));
                console.log(`dictionary loaded with ${this.dictionary.size} words`);
            });
    }

    private loadFromLocalStorage() {
        this.solution = WordleAppStoreService.getLocalStorageObject('alxwordle.solution') ?? '';
        this.chatProtocol = WordleAppStoreService.getLocalStorageObject('alxwordle.chatProtocol') ?? [];

        if (this.solution.length === 0) {
            this.pocApi.randomWord({ length: 5 }).then((proposal) => {
                this.solution = proposal.word;
                this.saveInLocalStorage();
                console.log( `ready with new solution: ${this.solution}`);
            });
        } else {
            console.log( `ready with stored solution: ${this.solution}`);
            const guessList = WordleAppStoreService.getLocalStorageObject('alxwordle.guessList') ?? [];
            const guessListEval = [];
            guessList.forEach((guess: string) => {
                this.guess(guess);
                this.updateCharStatus()
            });
            this.updateGameStatus();
        }
    }

    private saveInLocalStorage() {
        WordleAppStoreService.setLocalStorageObject('alxwordle.solution', this.solution);
        WordleAppStoreService.setLocalStorageObject('alxwordle.guessList', this.guessList);
        WordleAppStoreService.setLocalStorageObject('alxwordle.chatProtocol', this.chatProtocol);
    }

    clearLocalStorage() {
        localStorage.removeItem('alxwordle.solution');
        localStorage.removeItem('alxwordle.guessList');
        localStorage.removeItem('alxwordle.chatProtocol');
    }

    reset(solution: string) {
        this.clear();
        this.clearLocalStorage();
        this.setSolution(solution);
    }

    async isValidGuess(guess: string): Promise<boolean> {
        if (guess.length !== this.wordleSize) return false;
        if (!/^[A-Z]+$/i.test(guess)) return false;
        if (this.guessList.length > this.wordleSize) return false;
        // const isDictionaryWord = await this.pocApi.checkWordValidity({ word: guess });
        // return isDictionaryWord;
        return this.dictionary.has(guess.toUpperCase());
    }

    guess(guess: string): void {
        if (guess.length !== this.wordleSize) {
            throw new Error('Guess must be the same length as the wordle size');
        }

        if (this.guessList.length > this.wordleSize) {
            throw new Error('Too many guesses');
        }

        const normalizedGuess = WordleAppStoreService.normalizeCharSequence(guess);
        const guessResult = this.evaluateNormalizedGuess(normalizedGuess);

        this.guessList.push(normalizedGuess);
        this.guessListEval.push(guessResult);

        this.saveInLocalStorage();
    }


    /**
     * Classify the character according to the rule:
     *   - when the character is in the correct position, it is correct
     *  - when the character is not in the correct position, but is (still) in the solution, it exists
     *  - otherwise, it is incorrect
     */
    private evaluateNormalizedGuess(normalizedGuess: string): CharState[] {
        // remember the character counts of the solution
        const charCounts = WordleAppStoreService.createCharCountMap(this.solution);

        // start with a result array, where all the 'correct' positions are set
        const result = Array(this.wordleSize).fill(CharState.Unset);

        for (let i = 0; i < normalizedGuess.length; i++) {
            const char = normalizedGuess[i];
            if (this.solution[i] === char) {
                result[i] = CharState.Correct;
                charCounts[char]--;
            }
        }

        for (let i = 0; i < normalizedGuess.length; i++) {
            const char = normalizedGuess[i];
            let charState = result[i];

            // transition 'unset' to either 'exists' or 'incorrect'
            if (charState === CharState.Unset) {
                if (charCounts[char] > 0) {
                    charState = CharState.Exists;
                    charCounts[char]--;
                } else {
                    charState = CharState.Incorrect;
                }
            }

            result[i] = charState;
        }

        return result;
    }

    updateCharStatus() {
        if (this.guessList.length === 0) {
            this.charStatus = {};
        } else {
            const guessResult = this.guessListEval[this.guessListEval.length - 1];
            const guess = this.guessList[this.guessListEval.length - 1];
            for (let i = 0; i < guess.length; i++) {
                const char = guess[i];
                this.charStatus[char] = WordleAppStoreService.betterResultOf(this.charStatus[char], guessResult[i]);
            }
        }
    }

    updateGameStatus() {
        if (this.guessList.length === 0) {
            this.gameStatus.set(WordleGameStatus.NotStarted);
        } else {
            const guessResult = this.guessListEval[this.guessListEval.length - 1];
            if (guessResult.every((charState) => charState === CharState.Correct)) {
                this.gameStatus.set(WordleGameStatus.Won);
            } else if (this.guessList.length >= this.wordleSize) {
                this.gameStatus.set(WordleGameStatus.Lost);
            } else {
                this.gameStatus.set(WordleGameStatus.InProgress);
            }
        }
    }

    guessesAsClipboardString() {
        const guessHistory = this.guessListEval
            .map((g) => g.map((c) => WordleAppStoreService.charStateToUnicode(c)).join(''))
            .join('\n');
        const clipboardText = `Wordle, ${this.numGuesses} attempts\n\n${guessHistory}\n\nwordle.heim-rettner.de`;
        return clipboardText;
    }

    private static createCharCountMap(word: string): CharCounts {
        // create a set of the solution characters
        const solutionSet = new Set(word.split(''));

        // copy the solution set into a map with char counts
        const solutionMap: CharCounts  = {};
        for (const char of solutionSet) {
            solutionMap[char] = word.match(new RegExp(char, 'g'))?.length || 0;
        }

        return solutionMap;
    }

    private static normalizeCharSequence(chars: string): string {
        return chars.toUpperCase().normalize('NFD');
    }

    clear() {
        this.solution = '';
        this.guessList = [];
        this.chatProtocol = [];
        this.guessListEval = [];
        this.charStatus = {};
        this.gameStatus.set(WordleGameStatus.NotStarted);
    }

    private setSolution(word: string) {
        this.solution = word;
        this.wordleSize = word.length;
    }

    private static betterResultOf(state1: CharState, state2: CharState) {
        if (state1 === CharState.Correct) {
            return state1;
        } else if (state1 === CharState.Exists && state2 === CharState.Incorrect) {
            return state1;
        } else {
            return state2;
        }
    }

    private static getLocalStorageObject(key: string): any {
        const json = localStorage.getItem(key);
        return json ? JSON.parse(json) : null;
    }

    private static setLocalStorageObject(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static UnicodeCorrect = "🟩";
    static UnicodeExists = "🟨";
    static UnicodeIncorrect = "⬜";

    static charStateToUnicode(charState: CharState): string {
        switch (charState) {
            case CharState.Correct: return WordleAppStoreService.UnicodeCorrect;
            case CharState.Exists: return WordleAppStoreService.UnicodeExists;
            case CharState.Incorrect: return WordleAppStoreService.UnicodeIncorrect;
            default: return '?';
        }
    }
}
