/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { WordlGuessEvaluation } from './WordlGuessEvaluation';
import {
    WordlGuessEvaluationFromJSON,
    WordlGuessEvaluationFromJSONTyped,
    WordlGuessEvaluationToJSON,
} from './WordlGuessEvaluation';

/**
 * 
 * @export
 * @interface WordlGameEvaluation
 */
export interface WordlGameEvaluation {
    /**
     * 
     * @type {string}
     * @memberof WordlGameEvaluation
     */
    solution: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof WordlGameEvaluation
     */
    guesses: Array<string>;
    /**
     * 
     * @type {Array<WordlGuessEvaluation>}
     * @memberof WordlGameEvaluation
     */
    guessResults: Array<WordlGuessEvaluation>;
    /**
     * 
     * @type {string}
     * @memberof WordlGameEvaluation
     */
    gameState: WordlGameEvaluationGameStateEnum;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof WordlGameEvaluation
     */
    charStates: { [key: string]: string; };
}


/**
 * @export
 */
export const WordlGameEvaluationGameStateEnum = {
    InProgress: 'InProgress',
    Won: 'Won',
    Lost: 'Lost'
} as const;
export type WordlGameEvaluationGameStateEnum = typeof WordlGameEvaluationGameStateEnum[keyof typeof WordlGameEvaluationGameStateEnum];

/**
 * @export
 */
export const WordlGameEvaluationCharStatesEnum = {
    Unset: 'Unset',
    Exists: 'Exists',
    Correct: 'Correct',
    Incorrect: 'Incorrect'
} as const;
export type WordlGameEvaluationCharStatesEnum = typeof WordlGameEvaluationCharStatesEnum[keyof typeof WordlGameEvaluationCharStatesEnum];


/**
 * Check if a given object implements the WordlGameEvaluation interface.
 */
export function instanceOfWordlGameEvaluation(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "solution" in value;
    isInstance = isInstance && "guesses" in value;
    isInstance = isInstance && "guessResults" in value;
    isInstance = isInstance && "gameState" in value;
    isInstance = isInstance && "charStates" in value;

    return isInstance;
}

export function WordlGameEvaluationFromJSON(json: any): WordlGameEvaluation {
    return WordlGameEvaluationFromJSONTyped(json, false);
}

export function WordlGameEvaluationFromJSONTyped(json: any, ignoreDiscriminator: boolean): WordlGameEvaluation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'solution': json['solution'],
        'guesses': json['guesses'],
        'guessResults': ((json['guessResults'] as Array<any>).map(WordlGuessEvaluationFromJSON)),
        'gameState': json['gameState'],
        'charStates': json['charStates'],
    };
}

export function WordlGameEvaluationToJSON(value?: WordlGameEvaluation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'solution': value.solution,
        'guesses': value.guesses,
        'guessResults': ((value.guessResults as Array<any>).map(WordlGuessEvaluationToJSON)),
        'gameState': value.gameState,
        'charStates': value.charStates,
    };
}

