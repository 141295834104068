/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttemptFrequencyTable } from './AttemptFrequencyTable';
import {
    AttemptFrequencyTableFromJSON,
    AttemptFrequencyTableFromJSONTyped,
    AttemptFrequencyTableToJSON,
} from './AttemptFrequencyTable';

/**
 * 
 * @export
 * @interface DifficultyResponse
 */
export interface DifficultyResponse {
    /**
     * 
     * @type {string}
     * @memberof DifficultyResponse
     */
    word: string;
    /**
     * 
     * @type {string}
     * @memberof DifficultyResponse
     */
    baseform?: string;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    dwdsFrequency: number;
    /**
     * 
     * @type {string}
     * @memberof DifficultyResponse
     */
    dwdsWordType?: string;
    /**
     * 
     * @type {string}
     * @memberof DifficultyResponse
     */
    dwdsLemma?: string;
    /**
     * 
     * @type {string}
     * @memberof DifficultyResponse
     */
    dwdsUrl?: string;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    durationMs: number;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    count: number;
    /**
     * 
     * @type {AttemptFrequencyTable}
     * @memberof DifficultyResponse
     */
    attemptFrequencies: AttemptFrequencyTable;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    min: number;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    max: number;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    mean: number;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    stddev: number;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    skewness: number;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    kurtosis: number;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    median: number;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    q70: number;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    q80: number;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    q90: number;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    q95: number;
    /**
     * 
     * @type {number}
     * @memberof DifficultyResponse
     */
    q99: number;
}

/**
 * Check if a given object implements the DifficultyResponse interface.
 */
export function instanceOfDifficultyResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "word" in value;
    isInstance = isInstance && "dwdsFrequency" in value;
    isInstance = isInstance && "durationMs" in value;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "attemptFrequencies" in value;
    isInstance = isInstance && "min" in value;
    isInstance = isInstance && "max" in value;
    isInstance = isInstance && "mean" in value;
    isInstance = isInstance && "stddev" in value;
    isInstance = isInstance && "skewness" in value;
    isInstance = isInstance && "kurtosis" in value;
    isInstance = isInstance && "median" in value;
    isInstance = isInstance && "q70" in value;
    isInstance = isInstance && "q80" in value;
    isInstance = isInstance && "q90" in value;
    isInstance = isInstance && "q95" in value;
    isInstance = isInstance && "q99" in value;

    return isInstance;
}

export function DifficultyResponseFromJSON(json: any): DifficultyResponse {
    return DifficultyResponseFromJSONTyped(json, false);
}

export function DifficultyResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): DifficultyResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'word': json['word'],
        'baseform': !exists(json, 'baseform') ? undefined : json['baseform'],
        'dwdsFrequency': json['dwdsFrequency'],
        'dwdsWordType': !exists(json, 'dwdsWordType') ? undefined : json['dwdsWordType'],
        'dwdsLemma': !exists(json, 'dwdsLemma') ? undefined : json['dwdsLemma'],
        'dwdsUrl': !exists(json, 'dwdsUrl') ? undefined : json['dwdsUrl'],
        'durationMs': json['durationMs'],
        'count': json['count'],
        'attemptFrequencies': AttemptFrequencyTableFromJSON(json['attemptFrequencies']),
        'min': json['min'],
        'max': json['max'],
        'mean': json['mean'],
        'stddev': json['stddev'],
        'skewness': json['skewness'],
        'kurtosis': json['kurtosis'],
        'median': json['median'],
        'q70': json['q70'],
        'q80': json['q80'],
        'q90': json['q90'],
        'q95': json['q95'],
        'q99': json['q99'],
    };
}

export function DifficultyResponseToJSON(value?: DifficultyResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'word': value.word,
        'baseform': value.baseform,
        'dwdsFrequency': value.dwdsFrequency,
        'dwdsWordType': value.dwdsWordType,
        'dwdsLemma': value.dwdsLemma,
        'dwdsUrl': value.dwdsUrl,
        'durationMs': value.durationMs,
        'count': value.count,
        'attemptFrequencies': AttemptFrequencyTableToJSON(value.attemptFrequencies),
        'min': value.min,
        'max': value.max,
        'mean': value.mean,
        'stddev': value.stddev,
        'skewness': value.skewness,
        'kurtosis': value.kurtosis,
        'median': value.median,
        'q70': value.q70,
        'q80': value.q80,
        'q90': value.q90,
        'q95': value.q95,
        'q99': value.q99,
    };
}

