<div class="message-container" [@reveal]="state" (click)="onToastClick($event)" (dblclick)="hide()">
    <div class="toast-handle" [@handle-reveal]="state" (click)="onHandleClick($event)">
        <i class="fas fa-message-bot"></i>
    </div>
    <div class="title-bar"><i class="far fa-xmark-square" (click)="hide()"></i> {{title}}</div>
    <!--span *ngIf="!text" style="font-style: italic">no message yet</span-->
    <pre>{{text}}</pre>
    <div class="toast-footer">
        <i class="fas fa-robot"></i>
    </div>
</div>
