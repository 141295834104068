/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ChatCompletionRequestMessage } from './ChatCompletionRequestMessage';
import {
    ChatCompletionRequestMessageFromJSON,
    ChatCompletionRequestMessageFromJSONTyped,
    ChatCompletionRequestMessageToJSON,
} from './ChatCompletionRequestMessage';

/**
 * 
 * @export
 * @interface WordleAiCommentResponse
 */
export interface WordleAiCommentResponse {
    /**
     * 
     * @type {string}
     * @memberof WordleAiCommentResponse
     */
    message: string;
    /**
     * 
     * @type {string}
     * @memberof WordleAiCommentResponse
     */
    type: WordleAiCommentResponseTypeEnum;
    /**
     * 
     * @type {Array<ChatCompletionRequestMessage>}
     * @memberof WordleAiCommentResponse
     */
    protocol: Array<ChatCompletionRequestMessage>;
}


/**
 * @export
 */
export const WordleAiCommentResponseTypeEnum = {
    Ironic: 'ironic',
    Cynic: 'cynic',
    Depressed: 'depressed',
    Comforting: 'comforting',
    Strict: 'strict',
    Tip: 'tip',
    Joke: 'joke',
    Rhyme: 'rhyme'
} as const;
export type WordleAiCommentResponseTypeEnum = typeof WordleAiCommentResponseTypeEnum[keyof typeof WordleAiCommentResponseTypeEnum];


/**
 * Check if a given object implements the WordleAiCommentResponse interface.
 */
export function instanceOfWordleAiCommentResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "message" in value;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "protocol" in value;

    return isInstance;
}

export function WordleAiCommentResponseFromJSON(json: any): WordleAiCommentResponse {
    return WordleAiCommentResponseFromJSONTyped(json, false);
}

export function WordleAiCommentResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): WordleAiCommentResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'message': json['message'],
        'type': json['type'],
        'protocol': ((json['protocol'] as Array<any>).map(ChatCompletionRequestMessageFromJSON)),
    };
}

export function WordleAiCommentResponseToJSON(value?: WordleAiCommentResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'message': value.message,
        'type': value.type,
        'protocol': ((value.protocol as Array<any>).map(ChatCompletionRequestMessageToJSON)),
    };
}

