import { Injectable } from '@angular/core';
import Keycloak from "keycloak-js";

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    // initialize keycloak, without login we will not even start the angular app
    static keycloak = new Keycloak({
        url: 'https://keycloak.etractive.de/',
        realm: 'games',
        clientId: 'alxwordle',
    });

    static {
        AuthService.keycloak.onAuthSuccess = () => {
            console.log('onAuthSuccess');
        }
        AuthService.keycloak.onTokenExpired = AuthService.refreshAccessToken;
    }

    get username(): string {
        return AuthService.keycloak.tokenParsed?.['preferred_username'] ?? '';
    }

    constructor() {
    }

    static async initKeycloak() {
        try {
            const authenticated = await AuthService.keycloak.init({
                onLoad: 'check-sso',
                checkLoginIframe: false,
                enableLogging: true,
                silentCheckSsoRedirectUri: `${document.baseURI}/assets/silent-check-sso.html`,
            });
            console.log(`User is ${authenticated ? 'authenticated' : 'not authenticated'}`);
            if (authenticated) {
                console.log('tokenParsed', AuthService.keycloak.tokenParsed);
                console.log(AuthService.keycloak.userInfo);
            } else {
                await AuthService.keycloak.login({ });
            }
        } catch (error) {
            console.error('Failed to initialize adapter:', error);
        }
        return AuthService.keycloak.authenticated;
    }

    static async refreshAccessToken() {
        console.log('token expired, refreshing it');
        try {
            const refreshed = await AuthService.keycloak.updateToken(30);
            if (refreshed) console.log('successfully get a new token', AuthService.keycloak.token);
            else console.error('failed to get a new token');
        } catch (error) {
            console.error('failed to refresh token', error);
        }

    }

    async logout() {
        await AuthService.keycloak.logout();
    }

    static async accessToken(): Promise<string> {
        return AuthService.keycloak.token ?? '';
    }
}


