import {Component, CUSTOM_ELEMENTS_SCHEMA, EventEmitter, inject, Output} from '@angular/core';
import {WordleAppStoreService} from "../wordle-app-store.service";
import { NgFor, NgClass } from '@angular/common';

@Component({
    selector: 'app-wordle-keyboard',
    templateUrl: './wordle-keyboard.component.html',
    styleUrls: ['./wordle-keyboard.component.scss'],
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [NgFor, NgClass]
})
export class WordleKeyboardComponent {

    gameStore = inject(WordleAppStoreService);

    @Output() onKeyPress = new EventEmitter<string>();

    keyboard: string[][] = [
        ['Q', 'W', 'E', 'R', 'T', 'Z', 'U', 'I', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        ['&nbsp;&nbsp;<i class="fa fa-check"></i>&nbsp;&nbsp;', 'Y', 'X', 'C', 'V', 'B', 'N', 'M', '&nbsp;&nbsp;<i class="fa fa-delete-left"></i>&nbsp;&nbsp;']
    ];

    constructor() { }

    ngOnInit(): void {
    }

    onKeyButtonPress($event: MouseEvent, key: string) {
        console.log(key);
        window.navigator.vibrate(40);
        if (key.indexOf('fa-delete') > 0) {
            this.onKeyPress.emit('Backspace');
        } else if (key.indexOf('fa-check') > 0) {
            this.onKeyPress.emit('Enter');
        } else {
            this.onKeyPress.emit(key);
        }
    }

    keyStatusClass(key: string): string {
        return this.gameStore.charStatus[key]?.toString();
    }
}
