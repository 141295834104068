<fluent-dialog #dialog hidden trap-focus modal style="--dialog-height: 200px; --dialog-width: 300px">
    <div class="dialog-body">
        <h4 class="dialog-header"><i class="fas fa-flag-checkered"></i> Game Over</h4>
        <div class="dialog-body">
            <div style="font-size: 20px">Herzlichen Glückwunsch</div>
            <div>
                Du hast das Spiel gewonnen!
                Dafür hast du <span style="font-weight: bold">{{gameStore.numGuesses}}</span> Versuche gebraucht.
            </div>
        </div>
        <div class="dialog-toolbar">
            <fluent-button appearance="accent" (click)="share()"><i class="fas fa-share-alt"></i> Share</fluent-button>
            <fluent-button appearance="accent" (click)="onClose()"><i class="fas fa-close"></i> Close</fluent-button>
        </div>
    </div>
</fluent-dialog>
