<header>
    <fluent-button appearance="neutral" (click)="clearGrid()" title="alles löschen"><i class="fa fa-trash-xmark"></i></fluent-button>
    <fluent-button appearance="neutral" (click)="newSolution()" title="neues Wort"><i class="fas fa-sparkles"></i></fluent-button>
    <fluent-button appearance="neutral" (click)="computerGuess()"><i class="fas fa-brain-circuit"></i></fluent-button>
    <fluent-button appearance="neutral" (click)="test3(messageToast)"><i class="far fa-message-bot"></i></fluent-button>
    <!--
    <fluent-button appearance="neutral" (click)="test3(messageToast)">O</fluent-button>
    -->
    <span>{{authSvc.username}}</span>
    <fluent-button appearance="neutral" (click)="logout()"><i class="far fa-person-to-door"></i> </fluent-button>
</header>
<main>
    <router-outlet></router-outlet>
</main>
<footer>
    <!--
    <span #solution style="visibility: hidden">{{gameStore.solution}}</span>
    <fluent-button appearance="neutral" (click)="showSolution()"><i class="far fa-eye"></i></fluent-button>
    -->
</footer>
<app-message-toast #messageToast></app-message-toast>

