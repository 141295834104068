<div class="grid">
    <ng-container  *ngFor="let i of rowSizeRange">
        <ng-container  *ngFor="let j of colSizeRange">
            <div class="grid-cell"
                 [attr.data-grid-row]="i+1" [attr.data-grid-column]="j+1"
                 [style.grid-row]="i+1" [style.grid-column]="j+1"
                 [ngClass]="cellState(i, j)"
                 [class.focus]="hasFocus(i, j)" [@reveal]="cellRevealed(i, j)"
                 (click)="cellClicked(i, j)"
                >
                {{cell(i, j)}}
            </div>
        </ng-container>
    </ng-container>
</div>
