/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GuessMetrics
 */
export interface GuessMetrics {
    /**
     * 
     * @type {number}
     * @memberof GuessMetrics
     */
    optionsLeftBaseform: number;
    /**
     * 
     * @type {number}
     * @memberof GuessMetrics
     */
    optionsLeftAll: number;
}

/**
 * Check if a given object implements the GuessMetrics interface.
 */
export function instanceOfGuessMetrics(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "optionsLeftBaseform" in value;
    isInstance = isInstance && "optionsLeftAll" in value;

    return isInstance;
}

export function GuessMetricsFromJSON(json: any): GuessMetrics {
    return GuessMetricsFromJSONTyped(json, false);
}

export function GuessMetricsFromJSONTyped(json: any, ignoreDiscriminator: boolean): GuessMetrics {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionsLeftBaseform': json['optionsLeftBaseform'],
        'optionsLeftAll': json['optionsLeftAll'],
    };
}

export function GuessMetricsToJSON(value?: GuessMetrics | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionsLeftBaseform': value.optionsLeftBaseform,
        'optionsLeftAll': value.optionsLeftAll,
    };
}

