import {Component, CUSTOM_ELEMENTS_SCHEMA, ElementRef, EventEmitter, inject, ViewChild} from '@angular/core';
import {WordleAppStoreService} from "../../wordle-app-store.service";
import {Dialog as FluentDialog} from "@microsoft/fast-foundation";

@Component({
    selector: 'app-wordle-game-lost-popup',
    templateUrl: './wordle-game-lost-popup.component.html',
    styleUrls: ['./wordle-game-lost-popup.component.scss'],
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class WordleGameLostPopupComponent {
    gameStore = inject(WordleAppStoreService);

    @ViewChild('dialog') dialog!: ElementRef<FluentDialog>

    onInitialized = new EventEmitter<void>();
    onClosed = new EventEmitter<void>();

    ngAfterViewInit(): void {
        this.onInitialized.emit();
    }

    show() {
        this.dialog.nativeElement.show();
    }

    onClose() {
        this.dialog.nativeElement.hide();
        this.onClosed.emit();
    }

    async share() {
        await navigator.clipboard.writeText(this.gameStore.guessesAsClipboardString());
        alert('Copied to clipboard');
    }
}
