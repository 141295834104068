/* tslint:disable */
/* eslint-disable */
/**
 * OpenAPI definition
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AttemptFrequency } from './AttemptFrequency';
import {
    AttemptFrequencyFromJSON,
    AttemptFrequencyFromJSONTyped,
    AttemptFrequencyToJSON,
} from './AttemptFrequency';

/**
 * 
 * @export
 * @interface AttemptFrequencyTable
 */
export interface AttemptFrequencyTable {
    /**
     * 
     * @type {Array<AttemptFrequency>}
     * @memberof AttemptFrequencyTable
     */
    frequency: Array<AttemptFrequency>;
}

/**
 * Check if a given object implements the AttemptFrequencyTable interface.
 */
export function instanceOfAttemptFrequencyTable(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "frequency" in value;

    return isInstance;
}

export function AttemptFrequencyTableFromJSON(json: any): AttemptFrequencyTable {
    return AttemptFrequencyTableFromJSONTyped(json, false);
}

export function AttemptFrequencyTableFromJSONTyped(json: any, ignoreDiscriminator: boolean): AttemptFrequencyTable {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'frequency': ((json['frequency'] as Array<any>).map(AttemptFrequencyFromJSON)),
    };
}

export function AttemptFrequencyTableToJSON(value?: AttemptFrequencyTable | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'frequency': ((value.frequency as Array<any>).map(AttemptFrequencyToJSON)),
    };
}

