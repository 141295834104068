import {
    AfterViewInit,
    Component,
    CUSTOM_ELEMENTS_SCHEMA,
    effect,
    ElementRef,
    forwardRef,
    inject,
    OnInit,
    ViewChild
} from '@angular/core';
import {WordleAppStoreService, WordleGameStatus} from "./wordle-app-store.service";
import {WordleGameOverPopupComponent} from "./popups/wordle-game-over-popup/wordle-game-over-popup.component";
import {MessageDialogService} from "./message-dialog.service";
import {PocApi} from "../api-client/src";
import {WordleGameLostPopupComponent} from "./popups/wordle-game-lost-popup/wordle-game-lost-popup.component";
import {MessageToastComponent} from "./message-toast/message-toast.component";
import {AuthService} from "./service/auth.service";
import { RouterOutlet } from '@angular/router';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [RouterOutlet, MessageToastComponent]
})
export class AppComponent implements AfterViewInit {

    authSvc = inject(AuthService);
    gameStore = inject(WordleAppStoreService);
    messageDialogSvc = inject(MessageDialogService);
    pocApi = inject(PocApi);

    title = 'wordle-app';

    @ViewChild('messageToast') messageToastComponent!: MessageToastComponent;
    @ViewChild('solution') solutionEl!: ElementRef<HTMLElement>;

    static messageToast: MessageToastComponent;

    constructor() {
        effect(async () => {
            if (this.gameStore.gameStatus() === WordleGameStatus.Won) {
                await this.messageDialogSvc.showPopup(WordleGameOverPopupComponent);
            } else if (this.gameStore.gameStatus() === WordleGameStatus.Lost) {
                await this.messageDialogSvc.showPopup(WordleGameLostPopupComponent);
            }
        });
    }

    ngAfterViewInit(): void {
        AppComponent.messageToast = this.messageToastComponent;
    }

    clearGrid() {
        this.gameStore.reset(this.gameStore.solution);
    }

    async test() {
        await this.messageDialogSvc.showPopup(WordleGameOverPopupComponent);
    }

    async newSolution() {
        const proposal = await this.pocApi.randomWord({ length: 5 });
        this.gameStore.reset(proposal.word);
    }

    async computerGuess() {
        const solution = this.gameStore.solution;
        const result = await this.pocApi.solve({ solution: solution });
        this.gameStore.clear();
        this.gameStore.reset(solution);
        result.solution = result.solution.slice(0, 5);
        result.solution.forEach(s => this.gameStore.guess(s.word));
        this.gameStore.updateCharStatus();
        this.gameStore.updateGameStatus();
    }

    test3(messageToast: MessageToastComponent) {
        messageToast.show({
            message: 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
                + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
            + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n'
             + 'test asdlkjf asdf sadfh askdjfh asjdkfh alskjdhf askldjhf aslkjdhf aslkjdhf aslkdjhf asldkfj aslkjdf aslkdfjas lkdfjasldk fjsalk\n',
        });
    }

    async logout() {
        await this.authSvc.logout()
    }

    showSolution() {
        this.solutionEl.nativeElement.style.visibility = 'visible';
        setTimeout(() => this.solutionEl.nativeElement.style.visibility = 'hidden', 3000);
    }
}
