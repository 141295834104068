import {ApplicationRef, ComponentRef, createComponent, EventEmitter, inject, Injectable, Type,} from '@angular/core';

export interface MessageDialogComponent {
    show(): void;
    onInitialized: EventEmitter<void>;
    onClosed: EventEmitter<void>;
}


@Injectable({
    providedIn: 'root'
})
export class MessageDialogService {

    private applicationRef = inject(ApplicationRef);

    constructor() { }

    private createComponent<T extends MessageDialogComponent>(component: Type<T>): Promise<ComponentRef<T>> {
        const hostContainer = document.documentElement;
        const hostElement = document.createElement('div');
        hostContainer.appendChild(hostElement);

        const environmentInjector = this.applicationRef.injector;
        const componentRef = createComponent(component, { hostElement, environmentInjector});
        this.applicationRef.attachView(componentRef.hostView);
        return new Promise((resolve, reject) => {
            componentRef.instance.onInitialized.subscribe(() => resolve(componentRef));
        });
    }

    async showPopup<T extends MessageDialogComponent>(componentType: Type<T>): Promise<ComponentRef<T>> {
        const component = await this.createComponent(componentType);
        component.instance.onClosed.subscribe(() => component.destroy());
        component.instance.show();
        return component;
    }
}
