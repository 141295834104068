import {Component, ViewChild} from '@angular/core';
import {WordleGridComponent} from "../wordle-grid/wordle-grid.component";
import { WordleKeyboardComponent } from '../wordle-keyboard/wordle-keyboard.component';

@Component({
    selector: 'app-wordle-game',
    templateUrl: './wordle-game.component.html',
    styleUrls: ['./wordle-game.component.scss'],
    standalone: true,
    imports: [WordleGridComponent, WordleKeyboardComponent]
})
export class WordleGameComponent {

    @ViewChild(WordleGridComponent) grid!: WordleGridComponent;

    onKeyPress(key: string) {
        const keyEvent = new KeyboardEvent('keydown', {key: key});
        this.grid.keyEvent(keyEvent);
    }
}
